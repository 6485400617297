import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { PageContext } from 'contexts';
import { useCreateOnDemandRemoteAssist } from 'hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import { searchParams, sendCloseButtonClickedMessage } from 'utils';

export const DirectRaCreation = () => {
  const { goToErrorPage } = useContext(PageContext);
  const autoAssign = useMemo(
    () => searchParams.get('autoAssign') === 'true',
    [],
  );
  const createOnDemandRemoteAssist = useCreateOnDemandRemoteAssist();
  const [loading, setLoading] = useState(false);

  const createRA = useMemo(
    () => async () => {
      try {
        setLoading(true);
        await createOnDemandRemoteAssist();
        sendCloseButtonClickedMessage();
      } catch {
        goToErrorPage();
      }
    },
    [createOnDemandRemoteAssist, goToErrorPage],
  );

  useEffect(() => {
    if (autoAssign) {
      createRA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAssign]);

  return (
    <>
      {autoAssign ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={10}
          pb={10}
        >
          <CircularProgress size={150} thickness={5} />
        </Box>
      ) : (
        <Grid container direction="column" justifyContent="center">
          <Grid item pb={2}>
            <Typography variant="h6" textAlign="center" fontWeight={600}>
              We’re Ready to Start Your Call!
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" pb={4}>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                createRA();
              }}
              disabled={loading}
            >
              Start Call
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
