import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { generateTheme, searchParams } from 'utils';

import { AuthContext } from './AuthContext';
import { DeveloperApi } from '@hpx-it/developer-api-types';
import { Page } from 'types';
import { RemoteAssistContext } from './RemoteAssistContext';
import { Theme } from '@mui/material';

type BookingWidgetConfig = {
  theme?: {
    primary_color?: string;
    secondary_color?: string;
  };
  pages?: {
    skip: string[];
    welcome?: {
      items?: {
        title?: string;
        subtitle?: string;
        content?: string[];
        image_path?: string;
        image_attachment_id?: string;
      }[];
      length?: number;
    };
    availability_check?: {
      how_it_works_text?: string[][];
    };
  };
  defaults?: {
    trade?: string;
    product_type?: string;
    customer_is_member?: boolean;
  };
};

export type ServiceCodes =
  | {
      id: string;
      name: string;
      default: boolean;
      trade_name: string;
    }[]
  | null;

type ClientContextProps = {
  clientId: string;
  clientKey: string;
  clientName: string;
  clientProducts: string[];
  canBookWithHPA: boolean;
  canBookWithTechToTech: boolean;
  initialSupplyClientIdOrKey: string;
  supplyClientId: string;
  setSupplyClientIdOrKey: (id: string) => void;
  supplyClientName: string;
  bookingWidgetConfig?: BookingWidgetConfig;
  branding?: DeveloperApi.Clients.Branding;
  clientAttachmentIdToImageURL: Record<string, string>;
  setClientId: (id: string) => void;
  possibleClients: { id: string; name: string }[];
  serviceCodes: ServiceCodes;
  tradeNames: string[];
  hasChangedSupplyClient: boolean;
  hasOneServiceCode: boolean;
  loading: boolean;
  error: boolean;
};

type ClientProviderProps = {
  children: ReactNode;
  setCurrentTheme: (theme: Theme) => void;
};

const DEFAULT_CONTEXT: ClientContextProps = {
  clientId: '',
  clientKey: '',
  clientName: '',
  clientProducts: [],
  canBookWithHPA: false,
  canBookWithTechToTech: false,
  initialSupplyClientIdOrKey: '',
  supplyClientId: '',
  setSupplyClientIdOrKey: () => {},
  supplyClientName: '',
  setClientId: () => {},
  clientAttachmentIdToImageURL: {},
  possibleClients: [],
  serviceCodes: [],
  tradeNames: [],
  hasChangedSupplyClient: false,
  hasOneServiceCode: false,
  loading: false,
  error: false,
};

export const ClientContext = createContext<ClientContextProps>(DEFAULT_CONTEXT);

export const ClientProvider = ({
  children,
  setCurrentTheme,
}: ClientProviderProps) => {
  const { makeRequest, useRequest, waitingForAccessToken } =
    useContext(AuthContext);
  const { initialProductType } = useContext(RemoteAssistContext);
  const [clientIdOrKey, setClientIdOrKey] = useState(() => {
    return searchParams.get('client')?.toLowerCase() ?? 'hpa';
  });
  const [clientId, setClientId] = useState<string>('');
  const [supplyClientIdOrKey, setSupplyClientIdOrKey] = useState(() => {
    return (
      searchParams.get('supplyClient')?.toLowerCase() ??
      searchParams.get('transferTo')?.toLowerCase() ??
      'hpa'
    );
  });
  const initialSupplyClientIdOrKey = useMemo(
    () => supplyClientIdOrKey,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [supplyClientId, setSupplyClientId] = useState<string>('');
  const [bookingWidgetConfig, setBookingWidgetConfig] =
    useState<BookingWidgetConfig>();

  const {
    data: client = null,
    loading: clientLoading,
    error: clientError,
  } = useRequest<any>(
    `/clients-info?supply_client_id_or_key=${supplyClientIdOrKey}&demand_client_id_or_key=${clientIdOrKey}`,
    { skip: waitingForAccessToken },
    [clientIdOrKey, supplyClientIdOrKey],
  );

  const branding = useMemo(() => {
    if (client?.demand_client.branding) {
      return new DeveloperApi.Clients.Branding(client.demand_client.branding);
    }
    return undefined;
  }, [client]);

  const primaryColor = useMemo(
    () =>
      branding?.getPaletteColor('primary') ??
      searchParams.get('primaryColor') ??
      bookingWidgetConfig?.theme?.primary_color,
    [bookingWidgetConfig?.theme?.primary_color, branding],
  );
  const secondaryColor = useMemo(
    () =>
      branding?.getPaletteColor('secondary') ??
      searchParams.get('secondaryColor') ??
      bookingWidgetConfig?.theme?.secondary_color,
    [bookingWidgetConfig?.theme?.secondary_color, branding],
  );
  const [clientAttachmentIdToImageURL, setClientAttachmentIdToImageURL] =
    useState<Record<string, string>>({});
  const [clientImageURLsLoading, setClientImageURLsLoading] = useState<
    Set<string>
  >(new Set());
  const showPages = useMemo(() => {
    return searchParams.getAll('showPage');
  }, []);

  const {
    data: possibleClientsData = null,
    loading: possibleClientsLoading,
    error: possibleClientsError,
  } = useRequest<{ demand_clients: { id: string; name: string }[] }>(
    `/demand-clients?supply_client_id_or_key=${supplyClientIdOrKey}`,
    {
      skip: !showPages.includes(Page.CLIENT_SELECTION) || waitingForAccessToken,
    },
    [supplyClientIdOrKey],
  );

  const canBookWithHPA: boolean = useMemo(() => {
    return (
      (client?.demand_client.supply?.includes(
        process.env.REACT_APP_HPX_CLIENT_ID,
      ) ??
        false) &&
      initialSupplyClientIdOrKey !== process.env.REACT_APP_HPX_CLIENT_ID &&
      initialSupplyClientIdOrKey !== 'hpa'
    );
  }, [client, initialSupplyClientIdOrKey]);

  const canBookWithTechToTech: boolean = useMemo(
    () =>
      (client?.demand_client.products?.includes('TechToTech') ?? false) &&
      initialProductType !== 'TechToTech',
    [client, initialProductType],
  );

  useEffect(() => {
    if (client && client.demand_client.id !== clientId) {
      setClientId(client.demand_client.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (client && client.supply_client.id !== supplyClientId) {
      setSupplyClientId(client.supply_client.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (client && client.demand_client.widget_config) {
      setBookingWidgetConfig(client.demand_client.widget_config);
    }
  }, [client]);

  useEffect(() => {
    if (primaryColor || secondaryColor) {
      setCurrentTheme(generateTheme(primaryColor, secondaryColor));
    }
  }, [primaryColor, secondaryColor, setCurrentTheme]);

  useEffect(() => {
    const logoAssetId = branding?.getAttachmentId('logo');

    if (logoAssetId && supplyClientId && clientId) {
      (async () => {
        try {
          setClientImageURLsLoading((loading) => loading.add('logo'));
          const { data: blob } = await makeRequest<Blob>(
            `/attachments/${logoAssetId}/download?supply_client_id=${supplyClientId}&demand_client_id=${clientId}`,
            { responseType: 'blob' },
          );
          if (blob) {
            setClientAttachmentIdToImageURL((imageUrls) => ({
              ...imageUrls,
              ...(logoAssetId && {
                [logoAssetId]: URL.createObjectURL(blob),
              }),
            }));
          }
        } finally {
          setClientImageURLsLoading((loading) => {
            loading.delete('logo');
            return loading;
          });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branding?.assets, supplyClientId, clientId]);

  useEffect(() => {
    (async () => {
      if (bookingWidgetConfig?.pages?.welcome?.items) {
        setClientImageURLsLoading((loading) =>
          loading.add('welcomeCarouselItems'),
        );
        const urls = (
          await Promise.all(
            bookingWidgetConfig?.pages?.welcome?.items.map(async (item) => {
              if (item.image_attachment_id) {
                const { data: blob } = await makeRequest<Blob>(
                  `/attachments/${item.image_attachment_id}/download?supply_client_id=${supplyClientId}&demand_client_id=${clientId}`,
                  { responseType: 'blob' },
                );
                if (blob) {
                  return [
                    item.image_attachment_id,
                    URL.createObjectURL(blob),
                  ] as [string, string];
                }
              }
            }),
          )
        ).filter((item): item is [string, string] => item !== undefined);
        setClientAttachmentIdToImageURL((imageUrls) => ({
          ...imageUrls,
          ...Object.fromEntries(urls),
        }));
        setClientImageURLsLoading((loading) => {
          loading.delete('welcomeCarouselItems');
          return loading;
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingWidgetConfig?.pages?.welcome?.items]);

  const {
    data,
    clearData: clearTradesData,
    loading: tradesLoading,
    error: tradesError,
  } = useRequest<{
    trades: {
      name: string;
      service_codes: { id: string; name: string; default: boolean }[];
    }[];
  }>(
    `/trades?supply_client_id=${supplyClientId}&demand_client_id=${clientId}`,
    {
      skip: !supplyClientId || !clientId,
    },
    [supplyClientId, clientId],
  );

  const serviceCodes = useMemo(
    () =>
      data?.trades
        ?.map((tr) =>
          tr.service_codes.map((sc) => ({ ...sc, trade_name: tr.name })),
        )
        .flat() ?? [],
    [data],
  );

  const tradeNames = useMemo(
    () => (data?.trades?.map((tr) => tr.name).flat() ?? []).sort(),
    [data],
  );

  const hasOneServiceCode = useMemo(() => {
    return (serviceCodes ?? false) && serviceCodes?.length === 1;
  }, [serviceCodes]);

  return (
    <ClientContext.Provider
      value={{
        clientId: client?.demand_client?.id ?? '',
        clientKey: client?.demand_client?.key ?? '',
        clientName: client?.demand_client?.name ?? '',
        clientProducts: client?.demand_client?.products ?? [],
        canBookWithHPA,
        canBookWithTechToTech,
        initialSupplyClientIdOrKey: initialSupplyClientIdOrKey,
        supplyClientId: client?.supply_client?.id ?? '',
        setSupplyClientIdOrKey: (id: string) => {
          setSupplyClientIdOrKey((initialId) => {
            if (id !== initialId) {
              setSupplyClientId('');
              clearTradesData();
              return id;
            }
            return initialId;
          });
        },
        supplyClientName: client?.supply_client?.name ?? '',
        bookingWidgetConfig,
        branding,
        setClientId: (id: string) => {
          setClientId(id);
          setClientIdOrKey(id);
        },
        clientAttachmentIdToImageURL,
        possibleClients: possibleClientsData?.demand_clients ?? [],
        serviceCodes,
        tradeNames,
        hasChangedSupplyClient:
          initialSupplyClientIdOrKey !== supplyClientIdOrKey,
        hasOneServiceCode,
        loading:
          (!supplyClientId && !clientError) ||
          clientLoading ||
          (!data?.trades && !tradesError && !clientError) ||
          tradesLoading ||
          possibleClientsLoading ||
          clientImageURLsLoading.size !== 0,
        error: !!clientError || !!tradesError || !!possibleClientsError,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
