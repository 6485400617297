import { ArrowBack, Close } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Pages, searchParams, sendCloseButtonClickedMessage } from 'utils';
import { ReactNode, useContext, useMemo } from 'react';

import { Page } from 'types';
import { PageContext } from 'contexts';

type BaseModalProps = {
  page: Page;
  children?: ReactNode;
};

export const BaseModal = ({ page, children }: BaseModalProps) => {
  const theme = useTheme();
  const { pageHistory, goToPreviousPage, showBackButtonOverride } =
    useContext(PageContext);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const pageInfo = useMemo(() => Pages[page], [page]);

  const showCloseButton = useMemo(() => {
    return searchParams.get('showCloseButton') !== 'false';
  }, []);

  return (
    <Box
      mt={smallScreen ? '0' : '3%'}
      width={smallScreen ? '100%' : theme.breakpoints.values['sm']}
      height={smallScreen ? '100%' : 'fit-content'}
      bgcolor={theme.palette.common.white}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Grid container>
        {(showBackButtonOverride === undefined
          ? Pages[page].showBackButton !== false
          : showBackButtonOverride) &&
          pageHistory.length > 1 && (
            <Grid item>
              <Tooltip title="Back">
                <IconButton onClick={goToPreviousPage}>
                  <ArrowBack fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        {showCloseButton && (
          <Grid item xs display="flex" justifyContent="flex-end">
            <Tooltip title="Close">
              <IconButton onClick={sendCloseButtonClickedMessage}>
                <Close fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {children}
    </Box>
  );
};
