const {
  REACT_APP_APPLICANCES_SC_ID,
  REACT_APP_HVAC_SC_ID,
  REACT_APP_PLUMBING_SC_ID,
  REACT_APP_ELECTRICAL_SC_ID,
  REACT_APP_GARAGE_DOOR_SC_ID,
  REACT_APP_BALCONIES_DECK_SC_ID,
  REACT_APP_CABINETS_COUNTERTOPS_SC_ID,
  REACT_APP_CLEANING_SC_ID,
  REACT_APP_DOORS_SC_ID,
  REACT_APP_EXTERIOR_PAINT_SC_ID,
  REACT_APP_EXTERIOR_SIDING_STUCCO_SC_ID,
  REACT_APP_FENCING_SC_ID,
  REACT_APP_FLOORING_SC_ID,
  REACT_APP_HOME_EXTERIOR_SC_ID,
  REACT_APP_HOME_INTERIOR_SC_ID,
  REACT_APP_INTERIOR_PAINT_SC_ID,
  REACT_APP_LANDSCAPING_SC_ID,
  REACT_APP_LOCK_AND_KEY_SC_ID,
  REACT_APP_PAVED_SURFACES_MASONRY_SC_ID,
  REACT_APP_PEST_CONTROL_SC_ID,
  REACT_APP_POOL_SPA_SC_ID,
  REACT_APP_REMEDIATION_SC_ID,
  REACT_APP_ROOF_SC_ID,
  REACT_APP_SMART_HOME_SC_ID,
  REACT_APP_STRUCTURAL_ISSUES_SC_ID,
  REACT_APP_WINDOWS_SC_ID,
} = process.env;

// TODO: store on backend to allow per-client customization
export const serviceCodeMappings: Record<string, { label: string }[]> = {
  [REACT_APP_HVAC_SC_ID]: [
    {
      label: 'Heating, Cooling & Thermostats',
    },
    {
      label: 'Ventilation & Air Quality',
    },
  ],
  [REACT_APP_PLUMBING_SC_ID]: [
    {
      label: 'Plumbing, Toilets, Water Heaters',
    },
  ],
  [REACT_APP_ELECTRICAL_SC_ID]: [
    {
      label: 'Lights, Power, Electrical',
    },
  ],
  [REACT_APP_APPLICANCES_SC_ID]: [
    {
      label: 'Kitchen & Laundry Appliances',
    },
  ],
  [REACT_APP_GARAGE_DOOR_SC_ID]: [
    {
      label: 'Garage & Garage\nDoor',
    },
  ],
  [REACT_APP_BALCONIES_DECK_SC_ID]: [
    {
      label: 'Balconies & Deck',
    },
  ],
  [REACT_APP_CABINETS_COUNTERTOPS_SC_ID]: [
    {
      label: 'Cabinets & Countertops',
    },
  ],
  [REACT_APP_CLEANING_SC_ID]: [
    {
      label: 'Cleaning',
    },
  ],
  [REACT_APP_DOORS_SC_ID]: [
    {
      label: 'Doors',
    },
  ],
  [REACT_APP_EXTERIOR_PAINT_SC_ID]: [
    {
      label: 'Exterior Paint',
    },
  ],
  [REACT_APP_EXTERIOR_SIDING_STUCCO_SC_ID]: [
    {
      label: 'Exterior Siding & Stucco',
    },
  ],
  [REACT_APP_FENCING_SC_ID]: [
    {
      label: 'Fencing',
    },
  ],
  [REACT_APP_FLOORING_SC_ID]: [
    {
      label: 'Flooring',
    },
  ],
  [REACT_APP_HOME_EXTERIOR_SC_ID]: [
    {
      label: 'Home Exterior',
    },
  ],
  [REACT_APP_HOME_INTERIOR_SC_ID]: [
    {
      label: 'Home Interior',
    },
  ],
  [REACT_APP_INTERIOR_PAINT_SC_ID]: [
    {
      label: 'Interior Paint',
    },
  ],
  [REACT_APP_LANDSCAPING_SC_ID]: [
    {
      label: 'Landscaping',
    },
  ],
  [REACT_APP_LOCK_AND_KEY_SC_ID]: [
    {
      label: 'Lock & Key',
    },
  ],
  [REACT_APP_PAVED_SURFACES_MASONRY_SC_ID]: [
    {
      label: 'Paved Surfaces & Masonry',
    },
  ],
  [REACT_APP_PEST_CONTROL_SC_ID]: [
    {
      label: 'Pest Control',
    },
  ],
  [REACT_APP_POOL_SPA_SC_ID]: [
    {
      label: 'Pool & Spa',
    },
  ],
  [REACT_APP_REMEDIATION_SC_ID]: [
    {
      label: 'Remediation',
    },
  ],
  [REACT_APP_ROOF_SC_ID]: [
    {
      label: 'Roof',
    },
  ],
  [REACT_APP_SMART_HOME_SC_ID]: [
    {
      label: 'Smart Home',
    },
  ],
  [REACT_APP_STRUCTURAL_ISSUES_SC_ID]: [
    {
      label: 'Structural Issues',
    },
  ],
  [REACT_APP_WINDOWS_SC_ID]: [
    {
      label: 'Windows',
    },
  ],
};
