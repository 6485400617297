import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Notes, searchParams } from 'utils';
import { PARENT_RELATION_TYPES, ParentRelation } from 'types';

import { ClientContext } from './ClientContext';

const isParentRelationType = (input: string): input is ParentRelation => {
  return (PARENT_RELATION_TYPES as readonly string[]).includes(input);
};

type RemoteAssistContextProps = {
  serviceCodeId: string;
  setServiceCodeId: Dispatch<SetStateAction<string>>;
  initialProductType: string;
  productType: string;
  setProductType: Dispatch<SetStateAction<string>>;
  tradeName: string;
  notes: Notes;
  scheduledRemoteAssist: unknown;
  setScheduledRemoteAssist: Dispatch<SetStateAction<unknown>>;
  onDemandRemoteAssist: unknown;
  setOnDemandRemoteAssist: Dispatch<SetStateAction<unknown>>;
  parentRemoteAssist: { id: string; relation: ParentRelation } | null;
  assignTo: string | null;
  transferTo: { id: string; name: string } | null;
  reference?: string;
  setReference: Dispatch<SetStateAction<string | undefined>>;
  loading: boolean;
};

type RemoteAssistProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: RemoteAssistContextProps = {
  serviceCodeId: '',
  setServiceCodeId: () => {},
  initialProductType: '',
  productType: '',
  setProductType: () => {},
  tradeName: '',
  notes: new Notes('', '', ''),
  scheduledRemoteAssist: null,
  setScheduledRemoteAssist: () => {},
  onDemandRemoteAssist: null,
  setOnDemandRemoteAssist: () => {},
  parentRemoteAssist: null,
  assignTo: null,
  transferTo: null,
  setReference: () => {},
  loading: false,
};

export const RemoteAssistContext =
  createContext<RemoteAssistContextProps>(DEFAULT_CONTEXT);

export const RemoteAssistProvider = ({
  children,
}: RemoteAssistProviderProps) => {
  const {
    bookingWidgetConfig,
    serviceCodes,
    hasOneServiceCode,
    supplyClientId,
    supplyClientName,
    hasChangedSupplyClient,
    error: serviceCodesError,
  } = useContext(ClientContext);
  const [loading, setLoading] = useState(true);
  const [serviceCodeId, setServiceCodeId] = useState<string>('');
  const [tradeName, setTradeName] = useState<string>('');
  const [reference, setReference] = useState<string | undefined>(() => {
    return searchParams.get('reference') ?? undefined;
  });
  const initialProductType = useMemo(
    () =>
      searchParams.get('productType') ??
      bookingWidgetConfig?.defaults?.product_type ??
      '',
    [bookingWidgetConfig?.defaults?.product_type],
  );
  const [productType, setProductType] = useState<string>(initialProductType);
  const inputtedNotes = useMemo(() => {
    return searchParams.get('customerNotes') ?? '';
  }, []);
  const clientNotes = useMemo(() => {
    return searchParams.get('clientNotes') ?? '';
  }, []);
  const sessionNotes = useMemo(() => {
    return searchParams.get('sessionNotes') ?? '';
  }, []);
  const notes = useMemo(() => {
    return new Notes(inputtedNotes, clientNotes, sessionNotes);
  }, [clientNotes, inputtedNotes, sessionNotes]);
  const parentRemoteAssist = useMemo(() => {
    const id = searchParams.get('parentRemoteAssistId');
    if (!id) {
      return null;
    }
    const parentRemoteAssistRelation = searchParams
      .get('parentRemoteAssistRelation')
      ?.toLowerCase();
    return {
      id,
      relation:
        parentRemoteAssistRelation &&
        isParentRelationType(parentRemoteAssistRelation)
          ? parentRemoteAssistRelation
          : 'future_follow_up',
    };
  }, []);
  const assignTo = useMemo(() => {
    if (hasChangedSupplyClient) {
      return null;
    }
    return searchParams.get('assignTo') ?? null;
  }, [hasChangedSupplyClient]);
  const transferTo = useMemo(() => {
    return supplyClientId && supplyClientName
      ? { id: supplyClientId, name: supplyClientName }
      : null;
  }, [supplyClientId, supplyClientName]);

  const [scheduledRemoteAssist, setScheduledRemoteAssist] =
    useState<unknown>(null);
  const [onDemandRemoteAssist, setOnDemandRemoteAssist] =
    useState<unknown>(null);

  useEffect(() => {
    if (serviceCodes && hasOneServiceCode) {
      setServiceCodeId(serviceCodes[0].id);
    }
  }, [hasOneServiceCode, serviceCodes]);

  useEffect(() => {
    if (serviceCodes && serviceCodeId) {
      setTradeName(
        serviceCodes.find((sc) => sc.id === serviceCodeId)?.name ?? '',
      );
    }
  }, [serviceCodeId, serviceCodes]);

  useEffect(() => {
    if (!serviceCodes) {
      return;
    }
    const tradeParam =
      searchParams.get('trade') ?? bookingWidgetConfig?.defaults?.trade;
    if (tradeParam) {
      let serviceCode;
      if (tradeParam.startsWith('sc_')) {
        serviceCode = serviceCodes.find((sc) => sc.id === tradeParam);
      } else {
        serviceCode = serviceCodes.find(
          (sc) => sc.name === `${tradeParam.toUpperCase()}_RA`,
        );
      }
      if (serviceCode) {
        setServiceCodeId(serviceCode.id);
      }
    }
    setLoading(false);
  }, [bookingWidgetConfig?.defaults?.trade, serviceCodes]);

  useEffect(() => {
    if (serviceCodesError) {
      setLoading(false);
    }
  }, [serviceCodesError]);

  return (
    <RemoteAssistContext.Provider
      value={{
        serviceCodeId,
        setServiceCodeId,
        initialProductType,
        productType,
        setProductType,
        tradeName: tradeName,
        notes,
        scheduledRemoteAssist,
        setScheduledRemoteAssist,
        onDemandRemoteAssist,
        setOnDemandRemoteAssist,
        parentRemoteAssist,
        assignTo,
        transferTo,
        reference,
        setReference,
        loading,
      }}
    >
      {children}
    </RemoteAssistContext.Provider>
  );
};
