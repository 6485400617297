import { Card, Grid, Typography, useTheme } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

type SelectionCardProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
  icon: ReactNode;
  label: string;
};

export const SelectionCard = ({
  onClick,
  isSelected,
  icon,
  label,
}: SelectionCardProps) => {
  const theme = useTheme();
  return (
    <Card
      elevation={2}
      sx={{
        height: '100%',
        ...(isSelected && {
          backgroundColor: theme.palette.grey[200],
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        }),
        ':hover': {
          backgroundColor: theme.palette.grey[200],
          boxShadow: `2px 2px ${theme.palette.primary.light}, -2px -2px ${theme.palette.primary.light}, 2px -2px ${theme.palette.primary.light}, -2px 2px ${theme.palette.primary.light}`,
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        height="100%"
        p={2}
      >
        <Grid item>{icon}</Grid>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
          xs
          pt={1}
        >
          <Typography
            variant="body2"
            textAlign="center"
            fontWeight="bold"
            whiteSpace="pre-line"
            color={theme.palette.grey[800]}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
