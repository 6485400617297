import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ClientContext, PageContext } from 'contexts';
import { useContext, useState } from 'react';

import Carousel from 'react-material-ui-carousel';
import { merge } from 'lodash';
import { useInterval } from 'hooks';
import { useMemo } from 'react';

export type WelcomeCarouselItem = {
  title?: string;
  subtitle?: string;
  content?: string[];
  imagePath?: string;
};

// TODO: store on backend to allow per-client customization
const defaultCarouselItems: WelcomeCarouselItem[] = [
  {
    title: 'Pathlight Remote Assist',
    subtitle: 'The Fastest Way to Diagnose & Resolve Your Home Issues',
    content: [
      'Get connected with a Pathlight technician to virtually diagnose your issue and get it resolved.',
    ],
    imagePath: 'assets/images/welcome-view/welcome-desktop.png',
  },
  {
    title: 'Pathlight Remote Assist',
    subtitle: 'Video Call With A Technician',
    content: [
      'No wait - get professional help and support right on the call',
      "Use your phone's camera to enable the technician to investigate",
    ],
    imagePath: 'assets/images/welcome-view/video-call.png',
  },
  {
    title: 'Pathlight Remote Assist',
    subtitle: 'Fix It Remotely',
    content: [
      'Step-by-step live instruction and education from a pro, right on the call',
      'Safe and beginner-friendly',
    ],
    imagePath: 'assets/images/welcome-view/fix-remotely.svg',
  },
  {
    title: 'Pathlight Remote Assist',
    subtitle: 'Efficient In-Home Service',
    content: [
      "If it can't be fixed remotely, get a referral for in-home service",
      'Preliminary diagnosis ensures tech arrives at your home well prepared',
    ],
    imagePath: 'assets/images/welcome-view/efficient-service.svg',
  },
];

type CarouselItemViewProps = {
  carouselItem: WelcomeCarouselItem;
};

const CarouselItemView = ({ carouselItem }: CarouselItemViewProps) => {
  const theme = useTheme();
  const { clientAttachmentIdToImageURL, branding } = useContext(ClientContext);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const logoAssetId = branding?.getAttachmentId('logo');

  return (
    <Grid
      container
      direction={smallScreen ? 'column' : 'row'}
      pt={6}
      sx={{ ...(!smallScreen && { height: '25rem' }) }}
      width="100%"
      bgcolor={theme.palette.grey[100]}
    >
      <Grid item xs={7}>
        <Grid
          container
          direction="column"
          spacing={1}
          ml={1}
          height="100%"
          pr={2}
        >
          {logoAssetId && (
            <Grid item>
              <img
                width={'100px'}
                src={clientAttachmentIdToImageURL[logoAssetId]}
                alt="logo"
              />
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              color={theme.palette.grey[700]}
            >
              {carouselItem.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              fontWeight="bold"
              color={theme.palette.grey[900]}
            >
              {carouselItem.subtitle}
            </Typography>
          </Grid>
          {carouselItem.content && (
            <Grid item pl={1}>
              {carouselItem.content.length === 1 ? (
                <Typography variant="body1" color={theme.palette.grey[800]}>
                  {carouselItem.content[0]}
                </Typography>
              ) : (
                carouselItem.content.map((bullet, idx) => (
                  <Grid container direction="row" key={idx}>
                    <Grid item>
                      <Box
                        borderRadius="50%"
                        width="8px"
                        height="8px"
                        mr={2}
                        sx={{
                          backgroundColor: theme.palette.grey[400],
                          marginTop: '8px',
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        color={theme.palette.grey[800]}
                      >
                        {bullet}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid
          container
          direction="column"
          mb={2}
          justifyContent="flex-end"
          height="100%"
        >
          <Grid
            item
            xs
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              '>img': smallScreen ? { height: '200px' } : { width: '90%' },
            }}
          >
            <img src={carouselItem.imagePath} alt="Pathlight Technician" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Welcome = () => {
  const theme = useTheme();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const { bookingWidgetConfig, clientAttachmentIdToImageURL } =
    useContext(ClientContext);
  const { goToNextPage } = useContext(PageContext);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const carouselItems = useMemo(() => {
    const bookingWidgetWelcomeCarouselItems =
      bookingWidgetConfig?.pages?.welcome?.items?.map((item) => ({
        ...item,
        ...(item.image_attachment_id && {
          imagePath: clientAttachmentIdToImageURL[item.image_attachment_id],
        }),
      }));
    return [
      ...Array.from({
        length:
          bookingWidgetConfig?.pages?.welcome?.length ??
          Math.max(
            bookingWidgetWelcomeCarouselItems?.length ?? 0,
            defaultCarouselItems.length,
          ),
      }).keys(),
    ].map((n) => {
      return merge(
        defaultCarouselItems[n] ?? {},
        bookingWidgetWelcomeCarouselItems?.[n] ?? {},
      );
    });
  }, [
    bookingWidgetConfig?.pages?.welcome?.items,
    bookingWidgetConfig?.pages?.welcome?.length,
    clientAttachmentIdToImageURL,
  ]);

  const { reset } = useInterval(() => {
    setCarouselIndex((index) =>
      index === carouselItems.length - 1 ? 0 : index + 1,
    );
  }, 5000);

  const showCarousel = carouselItems.length > 1;

  return (
    <Grid container direction="column" position="relative">
      <Grid item sx={{ ...(smallScreen && { paddingBottom: '5rem' }) }}>
        <Carousel
          index={carouselIndex}
          onChange={(now?: number) => {
            if (now) {
              reset();
              setCarouselIndex(now);
            }
          }}
          swipe={showCarousel}
          navButtonsAlwaysInvisible={!showCarousel}
          autoPlay={false}
          indicators={showCarousel}
          activeIndicatorIconButtonProps={{
            className: 'active',
          }}
          sx={{
            height: smallScreen ? 'auto' : 'inherit',
            '.active': {
              '>div': {
                backgroundColor: theme.palette.secondary.main,
              },
            },
          }}
          IndicatorIcon={
            <Box
              borderRadius="50%"
              width="8px"
              height="8px"
              sx={{
                backgroundColor: theme.palette.action.disabled,
                margin: '2px',
              }}
            />
          }
        >
          {carouselItems.map((carouselItem, idx) => (
            <CarouselItemView carouselItem={carouselItem} key={idx} />
          ))}
        </Carousel>
      </Grid>
      <Grid
        item
        xs={12}
        width="100%"
        display="flex"
        justifyContent="center"
        p={2}
        zIndex={1}
        bgcolor="white"
        sx={{
          ...(smallScreen && {
            position: 'fixed',
            bottom: 0,
            top: 'auto',
            height: '5rem',
          }),
        }}
      >
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => goToNextPage()}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};
