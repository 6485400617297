import {
  Alert,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { HelpQuestionsIcon } from '../icons/24-help-questions';
import { CustomerContext } from 'contexts';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type CustomCustomerTextFieldProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  label: string;
  tooltip?: string;
  error?: string;
  warning?: string;
  required?: boolean;
  showMessagesState?: {
    showMessages: boolean;
    setShowMessages: Dispatch<SetStateAction<boolean>>;
  };
};

type CustomTextFieldProps = CustomCustomerTextFieldProps & {
  showMessagesState?: {
    showMessages: boolean;
    setShowMessages: Dispatch<SetStateAction<boolean>>;
  };
};

export const CustomTextField = ({
  value,
  setValue,
  label,
  tooltip,
  error,
  warning,
  required,
  showMessagesState: { showMessages, setShowMessages } = {
    showMessages: true,
    setShowMessages: () => {},
  },
}: CustomTextFieldProps) => {
  const theme = useTheme();
  return (
    <>
      <TextField
        value={value}
        onChange={(event) => {
          setShowMessages(false);
          setValue(event.target.value);
        }}
        label={label}
        fullWidth
        error={showMessages && !!error}
        helperText={showMessages ? error : null}
        required={required}
        FormHelperTextProps={{
          sx: {
            margin: '0',
            padding: '3px 14px',
            backgroundColor: theme.palette.grey[100],
          },
        }}
        InputProps={{
          ...(tooltip && {
            endAdornment: (
              <IconButton tabIndex={-1}>
                <Tooltip
                  arrow
                  title={
                    <Box p={1}>
                      <Typography color={theme.palette.grey[800]}>
                        {tooltip}
                      </Typography>
                    </Box>
                  }
                  PopperProps={{
                    sx: {
                      '>div': {
                        backgroundColor: '#D4EEF5',
                        '>span': {
                          color: '#D4EEF5',
                        },
                      },
                    },
                  }}
                >
                  <HelpQuestionsIcon color={theme.palette.primary.main} />
                </Tooltip>
              </IconButton>
            ),
          }),
        }}
        sx={{
          backgroundColor: theme.palette.common.white,
          '> div': {
            '> fieldset': {
              borderColor: theme.palette.primary.light,
            },
          },
        }}
      />
      {warning && !error && showMessages && (
        <Alert variant="outlined" severity="warning">
          {warning}
        </Alert>
      )}
    </>
  );
};

export const CustomCustomerTextField = (
  input: CustomCustomerTextFieldProps,
) => {
  const { validationResponseUpToDate, loading } = useContext(CustomerContext);

  const [showMessages, setShowMessages] = useState<boolean>(
    validationResponseUpToDate,
  );

  useEffect(() => {
    if (loading) {
      setShowMessages(false);
    }
  }, [loading]);

  useEffect(() => {
    if (validationResponseUpToDate) {
      setShowMessages(true);
    }
  }, [validationResponseUpToDate]);

  return (
    <CustomTextField
      {...input}
      showMessagesState={{ showMessages, setShowMessages }}
    />
  );
};
