import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import { searchParams } from 'utils';

// regex that for phone validation
const PHONE_VALIDATION_REGEX =
  /^\s*(?:\+?(1))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

type TechnicianContextProps = {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  emailAddress: string;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  validPhoneNumber: boolean;
};

type TechnicianProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: TechnicianContextProps = {
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  emailAddress: '',
  setEmailAddress: () => {},
  phoneNumber: '',
  setPhoneNumber: () => {},
  validPhoneNumber: true,
};

export const TechnicianContext =
  createContext<TechnicianContextProps>(DEFAULT_CONTEXT);

export const TechnicianProvider = ({ children }: TechnicianProviderProps) => {
  const [firstName, setFirstName] = useState<string>(() => {
    return searchParams.get('technicianFirstName') ?? '';
  });
  const [lastName, setLastName] = useState<string>(() => {
    return searchParams.get('technicianLastName') ?? '';
  });
  const [emailAddress, setEmailAddress] = useState<string>(() => {
    return searchParams.get('technicianEmail') ?? '';
  });
  const [phoneNumber, setPhoneNumber] = useState<string>(() => {
    return searchParams.get('technicianPhone') ?? '';
  });

  const validPhoneNumber = PHONE_VALIDATION_REGEX.test(phoneNumber.toString());

  return (
    <TechnicianContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        emailAddress,
        setEmailAddress,
        phoneNumber,
        setPhoneNumber,
        validPhoneNumber,
      }}
    >
      {children}
    </TechnicianContext.Provider>
  );
};
