import { Grid } from '@mui/material';
import { SelectionCard } from 'components';
import {
  BookWithHPAIcon,
  VideoCallOnsiteTechIcon,
  VideoCallResidentIcon,
} from '../../components/icons';
import { IconComponent } from '../../components/icons/types';
import { ArrowBackRounded } from '@mui/icons-material';

export const VALID_BOOKING_TYPE_OPTION_IDS = [
  'default',
  'back',
  'tech-to-tech',
  'book-with-hpa',
] as const;
export type BookingTypeOptionId =
  (typeof VALID_BOOKING_TYPE_OPTION_IDS)[number];
export type BookingTypeOption = {
  id: BookingTypeOptionId;
  label: string;
  iconComponent: IconComponent;
};

const defaultOptions: BookingTypeOption[] = [
  {
    id: 'default',
    label: 'Video Call with Resident',
    iconComponent: VideoCallResidentIcon,
  },
];

const backOption: BookingTypeOption = {
  id: 'back',
  label: 'Go Back',
  iconComponent: ({ color }) => (
    <ArrowBackRounded
      sx={{ color, width: 60, height: 60, stroke: color, strokeWidth: '1.5px' }}
    />
  ),
};

const techToTechOption: BookingTypeOption = {
  id: 'tech-to-tech',
  label: 'Video Call with On-Site Tech',
  iconComponent: VideoCallOnsiteTechIcon,
};

const bookWithHPAOption: BookingTypeOption = {
  id: 'book-with-hpa',
  label: 'Book with Pathlight Expert Tech',
  iconComponent: BookWithHPAIcon,
};

export const getSelection = (
  canBookWithHPA: boolean,
  canBookWithTechToTech: boolean,
  showBackButton: boolean,
  pickType: (id: string) => void,
  primaryColor: string,
) => {
  const options = [
    ...(showBackButton ? [backOption] : []),
    ...defaultOptions,
    ...(canBookWithHPA ? [bookWithHPAOption] : []),
    ...(canBookWithTechToTech ? [techToTechOption] : []),
  ];

  return options.map(({ id, label, iconComponent }) => (
    <BookingSelector
      id={id}
      label={label}
      IconComponent={iconComponent}
      primaryColor={primaryColor}
      pickType={pickType}
    />
  ));
};

type BookingSelectorProps = {
  id: string;
  label: string;
  IconComponent: IconComponent;
  primaryColor: string;
  pickType: (id: string) => void;
};

const BookingSelector = ({
  id,
  label,
  IconComponent,
  primaryColor,
  pickType,
}: BookingSelectorProps) => {
  return (
    <Grid item xs={6} sm={4}>
      <SelectionCard
        label={label}
        icon={<IconComponent color={primaryColor} />}
        onClick={() => {
          pickType(id);
        }}
      />
    </Grid>
  );
};
