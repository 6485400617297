import './App.css';
import 'utils/logging';

import {
  AudienceProvider,
  AuthProvider,
  ClientProvider,
  CustomerProvider,
  RemoteAssistProvider,
} from 'contexts';

import { Main } from 'pages';
import { TechnicianProvider } from 'contexts/TechnicianContext';
import { ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { generateTheme } from './utils/theme';

function App() {
  const [currentTheme, setCurrentTheme] = useState(generateTheme());

  return (
    <ThemeProvider theme={currentTheme}>
      <AuthProvider>
        <AudienceProvider>
          <ClientProvider setCurrentTheme={setCurrentTheme}>
            <CustomerProvider>
              <TechnicianProvider>
                <RemoteAssistProvider>
                  <Main />
                </RemoteAssistProvider>
              </TechnicianProvider>
            </CustomerProvider>
          </ClientProvider>
        </AudienceProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
