import { Card, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';
import {
  GetCategorySelectionCardStyle,
  GetCategorySelectionTypographyStyle,
} from './style';

type SelectionCardProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
  label: string;
};

export const CategorySelectionCard = ({
  onClick,
  isSelected,
  label,
}: SelectionCardProps) => {
  return (
    <Card {...GetCategorySelectionCardStyle(isSelected)} onClick={onClick}>
      <Typography {...GetCategorySelectionTypographyStyle()}>
        {label}
      </Typography>
    </Card>
  );
};
