export const PARENT_RELATION_TYPES = [
  'customer_callback',
  'future_follow_up',
  'transfer',
] as const;
export type ParentRelation = (typeof PARENT_RELATION_TYPES)[number];

export enum Page {
  WELCOME = 'WELCOME',
  CLIENT_SELECTION = 'CLIENT_SELECTION',
  PRODUCT_TYPE_SELECTION = 'PRODUCT_TYPE_SELECTION',
  TRADE_SELECTION = 'TRADE_SELECTION',
  NOTES_INPUT = 'NOTES_INPUT',
  CUSTOMER_INPUT = 'CUSTOMER_INPUT',
  TECHNICIAN_INPUT = 'TECHNICIAN_INPUT',
  DIRECT_RA_CREATION = 'DIRECT_RA_CREATION',
  ERROR = 'ERROR',
}

export enum PageTransitionType {
  INITIAL = 'INITIAL',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  ERROR = 'ERROR',
}

type PARENT_MESSAGE_PREFIX = 'HomeXRABookingWidget-';

export type RemoteAssistBookedMessage = {
  message: `${PARENT_MESSAGE_PREFIX}RemoteAssistBooked`;
  data: Record<string, unknown> & {
    type: 'ON_DEMAND' | 'SCHEDULED';
    customer: { zipCode: string };
    assignTo: string | null;
  };
};

export type ParentMessage =
  | { message: `${PARENT_MESSAGE_PREFIX}ReadyForAccessToken` }
  | { message: `${PARENT_MESSAGE_PREFIX}CloseButtonClick` }
  | { message: `${PARENT_MESSAGE_PREFIX}OutsideClick` }
  | { message: `${PARENT_MESSAGE_PREFIX}BackButtonClicked` }
  | {
      message: `${PARENT_MESSAGE_PREFIX}DateAndTimeSelected`;
      data: { zipCode: string };
    }
  | RemoteAssistBookedMessage
  | {
      message: `${PARENT_MESSAGE_PREFIX}BookingTypeSelected`;
      data: { bookingTypeId: string };
    }
  | {
      message: `${PARENT_MESSAGE_PREFIX}PageTransition`;
      data: {
        from: Page | undefined;
        to: Page;
        transitionType: PageTransitionType;
        zipCode: string;
      };
    };
