import {
  AudienceContext,
  ClientContext,
  PageContext,
  RemoteAssistContext,
  ServiceCodes,
} from 'contexts';
import { Box, Grid, Typography, Stack, Divider } from '@mui/material';

import { CategorySelectionCard } from 'components';
import { useContext } from 'react';
import {
  getTradeGridStyle,
  getTradeItemBoxStyle,
  getTradeItemGridStyle,
  getTradeItemTypographyStyle,
  getTradeSecondaryTypographyStyle,
  getTradeTypographyStyle,
} from './style';
import { serviceCodeMappings } from './serviceCodeMappings';

type TradeSelectorProps = {
  serviceCodeId: string;
  label: string;
};

const TradeSelector = ({ serviceCodeId, label }: TradeSelectorProps) => {
  const { serviceCodeId: currentServiceCodeId, setServiceCodeId } =
    useContext(RemoteAssistContext);
  const { goToNextPage } = useContext(PageContext);

  return (
    <Grid spacing={2}>
      <CategorySelectionCard
        label={label}
        isSelected={serviceCodeId === currentServiceCodeId}
        onClick={() => {
          setServiceCodeId(serviceCodeId);
          goToNextPage();
        }}
      />
    </Grid>
  );
};

const TradeItem = ({
  tradeName,
  serviceCodes,
}: {
  tradeName: string;
  serviceCodes: ServiceCodes;
}) => {
  const filteredServiceCodes = serviceCodes?.filter(
    (sc) => sc.trade_name === tradeName && serviceCodeMappings[sc.id],
  );

  return (
    <>
      <Typography {...getTradeItemTypographyStyle()}>{tradeName}</Typography>
      <Divider />
      <Box {...getTradeItemBoxStyle()}>
        <Grid container {...getTradeItemGridStyle()}>
          {filteredServiceCodes?.map((sc) =>
            serviceCodeMappings[sc.id]?.map(({ label }) => (
              <Grid item xs="auto" key={sc.id}>
                <TradeSelector serviceCodeId={sc.id} label={label} />
              </Grid>
            )),
          )}
          <Grid item xs />
        </Grid>
      </Box>
    </>
  );
};

export const TradeSelection = () => {
  const { audience } = useContext(AudienceContext);
  const { serviceCodes, tradeNames } = useContext(ClientContext);

  return (
    <Grid container {...getTradeGridStyle()}>
      <Grid item>
        <Typography {...getTradeTypographyStyle()}>
          {audience === 'customer'
            ? 'What Can We Help With?'
            : 'Select a service category'}
        </Typography>
      </Grid>
      {audience === 'customer' && (
        <Grid item>
          <Typography {...getTradeSecondaryTypographyStyle()}>
            We'll set you up for a video call with the right technician.
          </Typography>
        </Grid>
      )}
      <Stack justifyContent="center">
        {tradeNames.map((tradeName) => {
          return (
            <TradeItem
              tradeName={tradeName}
              serviceCodes={serviceCodes}
            ></TradeItem>
          );
        })}
      </Stack>
    </Grid>
  );
};
