import {
  BookingSelection,
  ClientSelection,
  CustomerInput,
  DirectRaCreation,
  Error,
  NotesInput,
  TechnicianInput,
  TradeSelection,
  Welcome,
} from 'pages';

import { Page, ParentRelation } from 'types';

type PageSkipCheckerInput = {
  serviceCodeId: string;
  customerInputError: boolean;
};

type NextPageAcceptorInput = {
  assignTo: boolean;
  transferTo: boolean;
  parentRemoteAssistRelation?: ParentRelation;
  clientDemandProducts: string[];
  hasOneServiceCode: boolean;
  productType: string;
  canBookWithHPA: boolean;
  canBookWithTechToTech: boolean;
  showBackButton: boolean;
};

export type NextPage = {
  page: Page;
  accept?: (input: NextPageAcceptorInput) => boolean;
};

type PageInfo = {
  // the component corresponding to the page
  component: JSX.Element;
  // the pages that follow this page, when skipped it will use the first in the array
  nextPages: NextPage[];
  // a checker to allow skipping, returns true when skip allowed (default: () => true)
  canSkip?: (input: PageSkipCheckerInput) => boolean;
  // whether the page should not be included in history when skipped (default: true)
  historyWhenSkipped?: boolean;
  // whether the page should have a back button (default: true)
  showBackButton?: boolean;
  // whether the page should be shown by default (default: true)
  showPage?: boolean;
  // whether the footer should be shown (default: true)
  showFooter?: boolean;
};

const inputNextPages: NextPage[] = [
  {
    page: Page.DIRECT_RA_CREATION,
    accept: (input) => input.assignTo,
  },
];

const productTypeSelectionNextPages: NextPage[] = [
  {
    page: Page.TRADE_SELECTION,
    accept: (input) => {
      return !input.hasOneServiceCode;
    },
  },
  {
    page: Page.NOTES_INPUT,
  },
];

export const Pages: Record<Page, PageInfo> = {
  [Page.WELCOME]: {
    component: <Welcome />,
    nextPages: [{ page: Page.CLIENT_SELECTION }],
    historyWhenSkipped: false,
    showFooter: false,
  },
  [Page.CLIENT_SELECTION]: {
    component: <ClientSelection />,
    nextPages: [
      {
        page: Page.PRODUCT_TYPE_SELECTION,
        accept: (input) =>
          input.canBookWithTechToTech ||
          input.canBookWithHPA ||
          input.showBackButton,
      },
      ...productTypeSelectionNextPages,
    ],
    historyWhenSkipped: false,
    showPage: false,
  },
  [Page.PRODUCT_TYPE_SELECTION]: {
    component: <BookingSelection />,
    nextPages: productTypeSelectionNextPages,
  },
  [Page.TRADE_SELECTION]: {
    component: <TradeSelection />,
    nextPages: [{ page: Page.NOTES_INPUT }],
    canSkip: ({ serviceCodeId }: PageSkipCheckerInput) => !!serviceCodeId,
  },
  [Page.NOTES_INPUT]: {
    component: <NotesInput />,
    nextPages: [
      {
        page: Page.TECHNICIAN_INPUT,
        accept: (input) => input.productType === 'TechToTech',
      },
      { page: Page.CUSTOMER_INPUT },
    ],
  },
  [Page.TECHNICIAN_INPUT]: {
    component: <TechnicianInput />,
    nextPages: inputNextPages,
  },
  [Page.CUSTOMER_INPUT]: {
    component: <CustomerInput />,
    nextPages: inputNextPages,
    canSkip: ({ customerInputError }: PageSkipCheckerInput) =>
      !customerInputError,
  },
  [Page.DIRECT_RA_CREATION]: {
    component: <DirectRaCreation />,
    nextPages: [],
    canSkip: () => false,
  },
  [Page.ERROR]: {
    component: <Error />,
    nextPages: [],
    canSkip: () => false,
    showFooter: false,
  },
};
