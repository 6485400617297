import { Box, CircularProgress, useTheme } from '@mui/material';
import {
  ClientContext,
  CustomerContext,
  PageContext,
  PageProvider,
  RemoteAssistContext,
} from 'contexts';
import { Pages, sendOutsideWidgetClickedMessage } from 'utils';
import { useContext, useEffect, useState } from 'react';

import { BaseModal } from 'components';
import { Page } from 'types';

const CurrentPage = () => {
  const { page, goToErrorPage } = useContext(PageContext);
  const { error: clientContextError } = useContext(ClientContext);
  const { error: customerContextError } = useContext(CustomerContext);

  useEffect(() => {
    if ((clientContextError || customerContextError) && page !== Page.ERROR) {
      goToErrorPage();
    }
  }, [page, clientContextError, customerContextError, goToErrorPage]);

  return <BaseModal page={page}>{Pages[page].component}</BaseModal>;
};

export const Main = () => {
  const theme = useTheme();
  const { loading: clientContextLoading } = useContext(ClientContext);
  const { loading: customerContextLoading } = useContext(CustomerContext);
  const { loading: remoteAssistContextLoading } =
    useContext(RemoteAssistContext);

  const [initialLoadInProgress, setInitialLoadInProgress] = useState(
    clientContextLoading ||
      customerContextLoading ||
      remoteAssistContextLoading,
  );

  useEffect(() => {
    if (
      !clientContextLoading &&
      !customerContextLoading &&
      !remoteAssistContextLoading
    ) {
      setInitialLoadInProgress(false);
    }
  }, [
    clientContextLoading,
    customerContextLoading,
    remoteAssistContextLoading,
  ]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      onMouseDown={sendOutsideWidgetClickedMessage}
    >
      {initialLoadInProgress ? (
        <Box height="100%" display="flex" alignItems="center">
          <CircularProgress
            style={{ color: theme.palette.grey[50] }}
            size={150}
            thickness={5}
          />
        </Box>
      ) : (
        <PageProvider>
          <CurrentPage />
        </PageProvider>
      )}
    </Box>
  );
};
