import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { searchParams } from 'utils';
import { AuthContext } from './AuthContext';

import { ClientContext } from './ClientContext';

type CustomerContextProps = {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  emailAddress: string;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  zipCode: string;
  setZipCode: Dispatch<SetStateAction<string>>;
  isMember: boolean;
  setIsMember: Dispatch<SetStateAction<boolean>>;
  isSixteenOrOlder: boolean;
  setIsSixteenOrOlder: Dispatch<SetStateAction<boolean>>;
  customerReference: string | undefined;
  setCustomerReference: Dispatch<SetStateAction<string | undefined>>;
  validationResponse: CustomerValidation | null;
  validationResponseUpToDate: boolean;
  runCustomerValidation: () => Promise<CustomerValidation | null>;
  loading: boolean;
  error: boolean;
};

type CustomerProviderProps = {
  children: ReactNode;
};

type ValidationComponent = {
  new_value?: string;
  warning?: string;
  error?: string;
};

type ValidationResponse = {
  valid: boolean;
  first_name?: ValidationComponent;
  last_name?: ValidationComponent;
  email?: ValidationComponent;
  phone?: ValidationComponent;
  service_location?: ValidationComponent;
};

class CustomerValidation implements ValidationResponse {
  valid: boolean;
  first_name?: ValidationComponent;
  last_name?: ValidationComponent;
  email?: ValidationComponent;
  phone?: ValidationComponent;
  service_location?: ValidationComponent;
  constructor(validationResponse: ValidationResponse) {
    this.valid = validationResponse.valid;
    this.first_name = validationResponse.first_name;
    this.last_name = validationResponse.last_name;
    this.email = validationResponse.email;
    this.phone = validationResponse.phone;
    this.service_location = validationResponse.service_location;
  }

  public hasWarning() {
    return (
      !!this.first_name?.warning ||
      !!this.last_name?.warning ||
      !!this.email?.warning ||
      !!this.phone?.warning ||
      !!this.service_location?.warning
    );
  }
}

const DEFAULT_CONTEXT: CustomerContextProps = {
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  emailAddress: '',
  setEmailAddress: () => {},
  phoneNumber: '',
  setPhoneNumber: () => {},
  zipCode: '',
  setZipCode: () => {},
  isMember: false,
  setIsMember: () => {},
  isSixteenOrOlder: false,
  setIsSixteenOrOlder: () => {},
  customerReference: undefined,
  setCustomerReference: () => {},
  validationResponse: new CustomerValidation({ valid: false }),
  validationResponseUpToDate: true,
  loading: false,
  error: false,
  runCustomerValidation: async () =>
    new CustomerValidation({
      valid: false,
    }),
};

export const CustomerContext =
  createContext<CustomerContextProps>(DEFAULT_CONTEXT);

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const { makeRequest, waitingForAccessToken } = useContext(AuthContext);
  const {
    bookingWidgetConfig,
    loading: clientContextLoading,
    clientId,
    supplyClientId,
  } = useContext(ClientContext);
  const [firstName, setFirstName] = useState<string>(() => {
    return searchParams.get('customerFirstName') ?? '';
  });
  const [customerReference, setCustomerReference] = useState<
    string | undefined
  >(() => {
    return searchParams.get('customerReference') ?? undefined;
  });
  const [lastName, setLastName] = useState<string>(() => {
    return searchParams.get('customerLastName') ?? '';
  });
  const [emailAddress, setEmailAddress] = useState<string>(() => {
    return searchParams.get('customerEmail') ?? '';
  });
  const [phoneNumber, setPhoneNumber] = useState<string>(() => {
    return searchParams.get('customerPhone') ?? '';
  });
  const [zipCode, setZipCode] = useState<string>(() => {
    return searchParams.get('customerPostalCode') ?? '';
  });
  const [isMember, setIsMember] = useState<boolean>(() => {
    return searchParams.get('customerIsMember') === 'true';
  });
  const [validationResponse, setValidationResponse] =
    useState<CustomerValidation | null>(null);
  const [validationResponseUpToDate, setValidationResponseUpToDate] =
    useState<boolean>(false);
  const [validationResponseLoading, setValidationResponseLoading] =
    useState<boolean>(true);
  const [validationResponseError, setValidationResponseError] = useState<
    string | null
  >(null);

  const runCustomerValidation = useCallback(async () => {
    setValidationResponseLoading(true);
    const { data, error } = await makeRequest<ValidationResponse>(
      `/verify-attendee?demand_client_id=${clientId}&supply_client_id=${supplyClientId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: emailAddress,
          phone: phoneNumber,
          service_location: zipCode,
        }),
      },
    );
    const customerValidation = data && new CustomerValidation(data);
    setValidationResponse(customerValidation);
    setValidationResponseError(error);
    setValidationResponseUpToDate(true);
    setValidationResponseLoading(false);
    return customerValidation;
  }, [
    clientId,
    emailAddress,
    firstName,
    lastName,
    makeRequest,
    phoneNumber,
    supplyClientId,
    zipCode,
  ]);

  useEffect(() => {
    setValidationResponseUpToDate(false);
  }, [emailAddress, firstName, lastName, phoneNumber, zipCode]);

  useEffect(() => {
    (async () => {
      if (!clientContextLoading && !waitingForAccessToken) {
        await runCustomerValidation();
        setValidationResponseUpToDate(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientContextLoading]);

  useEffect(() => {
    if (validationResponse?.phone?.new_value) {
      setPhoneNumber(validationResponse.phone.new_value);
    }
    if (validationResponse?.service_location?.new_value) {
      setZipCode(validationResponse.service_location.new_value);
    }
    if (validationResponse?.email?.new_value) {
      setEmailAddress(validationResponse.email.new_value);
    }
    if (validationResponse?.first_name?.new_value) {
      setFirstName(validationResponse.first_name?.new_value);
    }
    if (validationResponse?.last_name?.new_value) {
      setLastName(validationResponse.last_name?.new_value);
    }
  }, [validationResponse]);

  const [isSixteenOrOlder, setIsSixteenOrOlder] = useState<boolean>(() => {
    return searchParams.get('isSixteenOrOlder') === 'true';
  });

  useEffect(() => {
    if (
      !clientContextLoading &&
      !searchParams.get('customerIsMember') &&
      bookingWidgetConfig?.defaults?.customer_is_member !== undefined
    ) {
      setIsMember(bookingWidgetConfig.defaults?.customer_is_member);
    }
  }, [bookingWidgetConfig?.defaults?.customer_is_member, clientContextLoading]);

  return (
    <CustomerContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        emailAddress,
        setEmailAddress,
        phoneNumber,
        setPhoneNumber,
        zipCode,
        setZipCode,
        isMember,
        setIsMember,
        isSixteenOrOlder,
        setIsSixteenOrOlder,
        customerReference,
        setCustomerReference,
        validationResponse,
        validationResponseUpToDate,
        loading: validationResponseLoading,
        error: !!validationResponseError,
        runCustomerValidation,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
