import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import {
  AudienceContext,
  ClientContext,
  PageContext,
  RemoteAssistContext,
} from 'contexts';
import { useCallback, useContext, useEffect, useState } from 'react';

import { VALID_BOOKING_TYPE_OPTION_IDS, getSelection } from './BookingSelector';
import {
  searchParams,
  sendBackButtonClickedMessage,
  sendBookingTypeSelectedMessage,
} from 'utils';
import { logger } from '@hpx-it/react-app';

export const BookingSelection = () => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const { showBackButton } = useContext(AudienceContext);
  const {
    canBookWithHPA,
    canBookWithTechToTech,
    initialSupplyClientIdOrKey,
    setSupplyClientIdOrKey,
    loading,
  } = useContext(ClientContext);
  const { initialProductType, setProductType } =
    useContext(RemoteAssistContext);

  const { goToNextPage } = useContext(PageContext);

  const [buttonClicked, setButtonClicked] = useState<string>();

  const pickBookingType = useCallback(
    (id: string) => {
      if (!(VALID_BOOKING_TYPE_OPTION_IDS as readonly string[]).includes(id)) {
        logger.warn(`Invalid booking type id ${id} passed in`);
        return;
      }
      if (id === 'back') {
        sendBackButtonClickedMessage();
        return;
      }
      id === 'book-with-hpa'
        ? setSupplyClientIdOrKey(process.env.REACT_APP_HPX_CLIENT_ID ?? '')
        : setSupplyClientIdOrKey(initialSupplyClientIdOrKey);
      id === 'tech-to-tech'
        ? setProductType('TechToTech')
        : setProductType(initialProductType);
      setButtonClicked(id);
      sendBookingTypeSelectedMessage(id);
    },
    [
      initialProductType,
      initialSupplyClientIdOrKey,
      setProductType,
      setSupplyClientIdOrKey,
    ],
  );

  useEffect(() => {
    const presetBookingTypeId = searchParams.get('bookingTypeId');
    if (presetBookingTypeId) {
      pickBookingType(presetBookingTypeId);
      searchParams.delete('bookingTypeId');
    }
  }, [pickBookingType]);

  useEffect(() => {
    if (buttonClicked && !loading) {
      goToNextPage();
    }
  }, [loading, buttonClicked, goToNextPage]);

  return (
    <>
      {buttonClicked ? (
        <Box width="100%" height="100%" display="flex" justifyContent="center">
          <Box height="100%" display="flex" alignItems="center" padding="3em">
            <CircularProgress size={100} thickness={5} />
          </Box>
        </Box>
      ) : (
        <Box p={3} pt={0}>
          <Grid
            container
            direction="column"
            spacing={3}
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h5" textAlign="center" fontWeight={600}>
                What do You Want to Do?
              </Typography>
            </Grid>
            <Grid item container spacing={3} justifyContent="center">
              {getSelection(
                canBookWithHPA,
                canBookWithTechToTech,
                showBackButton,
                pickBookingType,
                primaryColor,
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
