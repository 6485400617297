import {
  DependencyList,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { logger } from '@hpx-it/react-app';
import { omit } from 'lodash';
import { sendReadyForAccessTokenMessage } from 'utils';

type ResponseType = 'json' | 'blob';

type RequestContext = RequestInit & { responseType?: ResponseType };

type AuthContextProps = {
  makeRequest<T>(
    path: string,
    context?: RequestContext,
  ): Promise<{ data: T | null; error: string | null }>;
  useRequest<T>(
    path: string,
    context?: RequestContext & {
      skip?: boolean;
    },
    deps?: DependencyList,
  ): {
    data: T | null;
    clearData: () => void;
    loading: boolean;
    error: string | null;
    fetch: () => void;
  };
  waitingForAccessToken: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: AuthContextProps = {
  makeRequest: async () => ({} as never),
  useRequest: () => ({
    data: null,
    loading: false,
    error: null,
    clearData: () => {},
    fetch: () => {},
  }),
  waitingForAccessToken: false,
};

export const AuthContext = createContext<AuthContextProps>(DEFAULT_CONTEXT);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const accessToken = useRef<string>(searchParams.get('token') ?? '');
  const [waitingForAccessToken, setWaitingForAccessToken] = useState(true);

  useEffect(() => {
    setTimeout(() => setWaitingForAccessToken(false), 1000);
  }, []);

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (
        event.data?.message === 'HomeXRABookingWidget-SetAccessToken' &&
        typeof event.data?.data === 'string'
      ) {
        accessToken.current = event.data?.data;
        setWaitingForAccessToken(false);
      }
    });
    sendReadyForAccessTokenMessage();
  }, []);

  async function makeRequest<T>(
    path: string,
    context?: RequestContext,
  ): Promise<{ data: T | null; error: string | null }> {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BOOKING_INTEGATION_GATEWAY_URL}${path}`,
        {
          ...omit(context, 'responseType'),
          headers: {
            ...context?.headers,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(accessToken.current && {
              authorization: `Bearer ${accessToken.current}`,
            }),
          },
        },
      );
      if (res.ok) {
        const body = await res[context?.responseType ?? 'json']();
        logger.info('Request made to booking-integration-service', {
          path,
          context,
          body,
        });
        return { data: body, error: null };
      } else {
        const message = await res.json();
        logger.error('Error from booking-integration-service', { message });
        return { data: null, error: message };
      }
    } catch (err) {
      return {
        data: null,
        error: (err as { message?: string }).message ?? JSON.stringify(err),
      };
    }
  }

  function useRequest<T>(
    path: string,
    context?: RequestContext & { skip?: boolean },
    dependencies?: DependencyList,
  ) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const preformRequest = useCallback(() => {
      let ignore = false;
      const fetchT = async () => {
        setLoading(true);
        setError(null);
        const { data, error } = await makeRequest<T>(
          path,
          omit(context, 'skip'),
        );
        if (!ignore) {
          setData(data);
          setError(error);
          setLoading(false);
        }
      };
      fetchT();
      return () => {
        ignore = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies ?? [path]);

    useEffect(() => {
      if (!context?.skip) {
        preformRequest();
      } else {
        setLoading(false);
      }
    }, [context?.skip, preformRequest]);

    return {
      data,
      clearData: () => setData(null),
      loading,
      error,
      fetch: preformRequest,
    };
  }

  return (
    <AuthContext.Provider
      value={{ makeRequest, useRequest, waitingForAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
