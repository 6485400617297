import { Card, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const GetCategorySelectionCardStyle = (
  isSelected: boolean | undefined,
): ComponentProps<typeof Card> => ({
  elevation: 2,
  sx: {
    width: 'fit-content',
    padding: (theme) => theme.spacing(1),
    backgroundColor: (theme) => theme.palette.grey[200],
    boxShadow: 'none',
    ...(isSelected && {
      backgroundColor: (theme) => theme.palette.grey[200],
      border: (theme) => `1px solid ${theme.palette.primary.light}`,
    }),
    ':hover': {
      backgroundColor: (theme) => theme.palette.grey[200],
      boxShadow: (theme) =>
        `2px 2px ${theme.palette.primary.light}, -2px -2px ${theme.palette.primary.light}, 2px -2px ${theme.palette.primary.light}, -2px 2px ${theme.palette.primary.light}`,
      cursor: 'pointer',
    },
  },
});

export const GetCategorySelectionTypographyStyle = (): ComponentProps<
  typeof Typography
> => ({
  variant: 'body2',
  textAlign: 'center',
  fontWeight: 'normal',
  whiteSpace: 'pre-line',
  color: (theme) => theme.palette.grey[800],
});
