import { Button, Grid, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { ClientContext, PageContext } from 'contexts';

import { useContext } from 'react';

export const ClientSelection = () => {
  const theme = useTheme();
  const { goToNextPage } = useContext(PageContext);
  const {
    clientId,
    setClientId,
    possibleClients,
    loading: clientsLoading,
  } = useContext(ClientContext);

  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Grid item pb={2}>
          <Typography variant="h5" textAlign="center" fontWeight={600}>
            Select Client
          </Typography>
        </Grid>
        <Grid item bgcolor={theme.palette.grey[100]} p={4}>
          <Select
            value={clientId}
            onChange={(event) => {
              setClientId(event.target.value);
            }}
            fullWidth
            sx={{
              backgroundColor: theme.palette.common.white,
              '> div': {
                '> fieldset': {
                  borderColor: theme.palette.primary.light,
                },
              },
            }}
          >
            {possibleClients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={clientsLoading}
            onClick={() => goToNextPage()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
