import { ReactNode, createContext, useMemo } from 'react';

import { searchParams } from 'utils';

const AUDIENCE_TYPES = ['customer', 'agent', 'technician'] as const;
type AudienceType = (typeof AUDIENCE_TYPES)[number];

type AudienceContextProps = {
  audience: AudienceType;
  showBackButton: boolean;
};

type AudienceProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: AudienceContextProps = {
  audience: 'customer',
  showBackButton: false,
};

const isAudienceType = (input: string): input is AudienceType => {
  return (AUDIENCE_TYPES as readonly string[]).includes(input);
};

export const AudienceContext =
  createContext<AudienceContextProps>(DEFAULT_CONTEXT);

export const AudienceProvider = ({ children }: AudienceProviderProps) => {
  const audience = useMemo(() => {
    const audienceParam = searchParams.get('audience');
    return audienceParam && isAudienceType(audienceParam)
      ? audienceParam
      : 'customer';
  }, []);

  const showBackButton = useMemo(
    () => searchParams.get('showBackButton') === 'true',
    [],
  );

  return (
    <AudienceContext.Provider value={{ audience, showBackButton }}>
      {children}
    </AudienceContext.Provider>
  );
};
