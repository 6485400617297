import { Box, Grid, Typography } from "@mui/material";

export const Error = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={1}
      pb={2}
    >
      <Grid item>
        <img src="assets/icons/oops-error-modal.svg" alt="oops" width="100%" />
      </Grid>
      <Grid item>
        <Typography variant="h5" textAlign="center" fontWeight="bold">
          Whoops! We Got Our Wires Crossed
        </Typography>
      </Grid>
      <Grid item>
        <Box pl={1} pr={1}>
          <Typography variant="h6" textAlign="center">
            Something's not quite right. You can try again by hitting back or
            restarting your booking.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
