import { createTheme } from '@mui/material/styles';

const DEFAULT_PRIMARY_COLOR = '#019592';
const DEFAULT_SECONDARY_COLOR = '#0086CF';

export const generateTheme = (
  primaryColor = DEFAULT_PRIMARY_COLOR,
  secondaryColor?: string,
) => {
  const mainSecondaryColor =
    primaryColor === DEFAULT_PRIMARY_COLOR
      ? DEFAULT_SECONDARY_COLOR
      : secondaryColor ?? '#FFFFFF';

  return createTheme({
    palette: {
      grey: { 50: '#AEB9C5' },
      primary: { main: primaryColor },
      secondary: { main: mainSecondaryColor },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.color === 'primary' && {
              backgroundColor: theme.palette.primary.main,
            }),
            fontWeight: 700,
            padding: '1rem 2rem',
          }),
        },
      },
    },
  });
};
