import { Button, Grid, Typography, useTheme } from '@mui/material';
import { CustomTextField } from 'components';
import { PageContext, TechnicianContext } from 'contexts';
import { useContext } from 'react';

export const TechnicianInput = () => {
  const theme = useTheme();
  const { goToNextPage } = useContext(PageContext);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    emailAddress,
    setEmailAddress,
    phoneNumber,
    setPhoneNumber,
    validPhoneNumber,
  } = useContext(TechnicianContext);

  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Grid item pb={3}>
          <Typography variant="h5" textAlign="center" fontWeight={600}>
            Technician Information
          </Typography>
        </Grid>
        <Grid item bgcolor={theme.palette.grey[100]} p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                value={emailAddress}
                setValue={setEmailAddress}
                label="Email Address"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                value={firstName}
                setValue={setFirstName}
                label="First Name"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                value={lastName}
                setValue={setLastName}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                required
                value={phoneNumber}
                setValue={setPhoneNumber}
                label="Phone Number"
                tooltip="We use this to send you SMS appointment reminders and in case you experience difficulty connecting to your session. "
                error={
                  phoneNumber
                    ? validPhoneNumber
                      ? ''
                      : 'Not a valid US or Canadian phone number.'
                    : 'You must enter a phone number.'
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" p={2}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={!validPhoneNumber}
            onClick={async () => {
              goToNextPage();
            }}
          >
            Start Call
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
