import { Button, Checkbox, Grid, Typography, useTheme } from '@mui/material';
import { CustomCustomerTextField } from 'components';
import { AudienceContext, CustomerContext, PageContext } from 'contexts';
import { useContext, useEffect, useMemo } from 'react';
import { searchParams } from 'utils';

export const CustomerInput = () => {
  const theme = useTheme();
  const { audience } = useContext(AudienceContext);
  const { goToNextPage } = useContext(PageContext);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    emailAddress,
    setEmailAddress,
    phoneNumber,
    setPhoneNumber,
    zipCode,
    setZipCode,
    isMember,
    setIsMember,
    isSixteenOrOlder,
    setIsSixteenOrOlder,
    validationResponse,
    validationResponseUpToDate,
    runCustomerValidation,
    loading,
  } = useContext(CustomerContext);

  const showMemberToggle = useMemo(() => {
    return searchParams.get('showMemberToggle') === 'true';
  }, []);

  useEffect(() => {
    const splitFirstName = firstName.split(' ');

    if (splitFirstName.length > 1) {
      const [first, ...last] = splitFirstName;
      setFirstName(first);
      setLastName(last.join(' '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName]);

  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Grid item pb={1}>
          <Typography variant="h5" textAlign="center" fontWeight={600}>
            {audience === 'customer'
              ? 'Your Information'
              : 'Customer Information'}
          </Typography>
        </Grid>
        {audience === 'customer' && (
          <Grid item pb={2} pl={10} pr={10}>
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="textSecondary"
            >
              Give us a little more information so we can connect you with an
              expert technician.
            </Typography>
          </Grid>
        )}
        <Grid item bgcolor={theme.palette.grey[100]} p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomCustomerTextField
                value={emailAddress}
                setValue={setEmailAddress}
                label="Email Address"
                error={validationResponse?.email?.error}
                warning={validationResponse?.email?.warning}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomCustomerTextField
                value={firstName}
                setValue={setFirstName}
                label="First Name"
                error={validationResponse?.first_name?.error}
                warning={validationResponse?.first_name?.warning}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomCustomerTextField
                value={lastName}
                setValue={setLastName}
                label="Last Name"
                error={validationResponse?.last_name?.error}
                warning={validationResponse?.last_name?.warning}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCustomerTextField
                value={phoneNumber}
                setValue={setPhoneNumber}
                label="Phone Number"
                tooltip="We use this to send you SMS appointment reminders and in case you experience difficulty connecting to your session. "
                error={validationResponse?.phone?.error}
                warning={validationResponse?.phone?.warning}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomCustomerTextField
                value={zipCode}
                setValue={setZipCode}
                label="Zip Code"
                tooltip="This service is only available in the US and Canada. We use this to determine your service eligibility. "
                error={validationResponse?.service_location?.error}
                warning={validationResponse?.service_location?.warning}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <Checkbox
                checked={isSixteenOrOlder}
                onChange={(e) => {
                  setIsSixteenOrOlder(e.target.checked);
                }}
              />
              <Typography variant="subtitle2">
                {`I confirm that ${
                  audience === 'customer' ? 'I am' : 'the customer is'
                } at least 18 years of age*`}
              </Typography>
            </Grid>
            {showMemberToggle && (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle1">Member?</Typography>
                <Checkbox
                  checked={isMember}
                  onChange={(e) => {
                    setIsMember(e.target.checked);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={async () => {
              if (validationResponseUpToDate && validationResponse?.valid) {
                goToNextPage();
                return;
              }
              const newValidationResponse = await runCustomerValidation();
              if (
                newValidationResponse?.valid &&
                !newValidationResponse.hasWarning()
              ) {
                goToNextPage();
                return;
              }
            }}
            disabled={
              !isSixteenOrOlder ||
              (validationResponseUpToDate &&
                validationResponse?.valid === false) ||
              loading
            }
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
