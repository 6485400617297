import { Box, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getTradeGridStyle = (): ComponentProps<typeof Grid> => ({
  direction: 'column',
  spacing: 2,
  justifyContent: 'center',
  overflow: 'auto',
});

export const getTradeTypographyStyle = (): ComponentProps<
  typeof Typography
> => ({
  variant: 'h4',
  textAlign: 'center',
  fontWeight: 300,
  paddingBottom: 2,
});

export const getTradeSecondaryTypographyStyle = (): ComponentProps<
  typeof Typography
> => ({
  variant: 'subtitle1',
  textAlign: 'center',
  color: 'textSecondary',
});

export const getTradeItemTypographyStyle = (): ComponentProps<
  typeof Typography
> => ({
  paddingTop: 1,
  align: 'left',
  variant: 'caption',
  sx: { fontWeight: 'bold' },
});

export const getTradeItemBoxStyle = (): ComponentProps<typeof Box> => ({
  paddingTop: 1,
  paddingLeft: 1,
  paddingBottom: 2,
  sx: { flexGrow: 1 },
});

export const getTradeItemGridStyle = (): ComponentProps<typeof Grid> => ({
  spacing: 1,
  wrap: 'wrap',
});
