import { useCallback, useContext } from 'react';
import { CustomerContext } from 'contexts';
import {
  Page,
  PageTransitionType,
  RemoteAssistBookedMessage,
  ParentMessage,
} from 'types';

const sendParentMessage = (message: ParentMessage) => {
  window.parent.postMessage(message, '*');
};

export const sendReadyForAccessTokenMessage = () => {
  sendParentMessage({ message: 'HomeXRABookingWidget-ReadyForAccessToken' });
};

export const sendCloseButtonClickedMessage = () => {
  sendParentMessage({ message: 'HomeXRABookingWidget-CloseButtonClick' });
};

export const sendOutsideWidgetClickedMessage = () => {
  sendParentMessage({ message: 'HomeXRABookingWidget-OutsideClick' });
};

export const sendDateAndTimeSelectedMessage = (zipCode: string) => {
  sendParentMessage({
    message: 'HomeXRABookingWidget-DateAndTimeSelected',
    data: { zipCode },
  });
};

export const sendRemoteAssistBookedMessage = (
  remoteAssist: RemoteAssistBookedMessage['data'],
) => {
  sendParentMessage({
    message: 'HomeXRABookingWidget-RemoteAssistBooked',
    data: remoteAssist,
  });
};

export const sendBookingTypeSelectedMessage = (bookingTypeId: string) => {
  sendParentMessage({
    message: 'HomeXRABookingWidget-BookingTypeSelected',
    data: { bookingTypeId },
  });
};

export const sendBackButtonClickedMessage = () => {
  sendParentMessage({ message: 'HomeXRABookingWidget-BackButtonClicked' });
};

const sendPageTransitionMessage = (
  from: Page | undefined,
  to: Page,
  transitionType: PageTransitionType,
  zipCode: string,
) => {
  sendParentMessage({
    message: 'HomeXRABookingWidget-PageTransition',
    data: {
      from,
      to,
      transitionType,
      zipCode,
    },
  });
};

export const useSendPageTransitionMessage = () => {
  const { zipCode } = useContext(CustomerContext);

  return useCallback(
    (from: Page | undefined, to: Page, transitionType: PageTransitionType) =>
      sendPageTransitionMessage(from, to, transitionType, zipCode),
    [zipCode],
  );
};
