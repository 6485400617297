import { AudienceContext, PageContext, RemoteAssistContext } from 'contexts';
import { Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

export const NotesInput = () => {
  const theme = useTheme();
  const { audience } = useContext(AudienceContext);
  const { goToNextPage } = useContext(PageContext);
  const { notes, reference, setReference } = useContext(RemoteAssistContext);
  const [referenceInput, setReferenceInput] = useState(reference);
  const [notesInput, setNotesInput] = useState(notes.notes);

  useEffect(() => {
    notes.setNotes(notesInput);
  }, [notes, notesInput]);

  useEffect(() => {
    setReference(referenceInput);
  }, [referenceInput, setReference]);

  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Grid item pb={2}>
          <Typography variant="h5" textAlign="center" fontWeight={600}>
            {audience === 'customer'
              ? 'Tell Us About Your Issue'
              : 'Issue Information'}
          </Typography>
        </Grid>
        <Grid item bgcolor={theme.palette.grey[100]} p={4}>
          {audience !== 'customer' && (
            <Grid item pb={2}>
              <TextField
                value={referenceInput}
                onChange={(event) => {
                  setReferenceInput(event.target.value);
                }}
                fullWidth
                placeholder="Work Order ID (optional)..."
                sx={{
                  backgroundColor: theme.palette.common.white,
                  '> div': {
                    '> fieldset': {
                      borderColor: theme.palette.primary.light,
                    },
                  },
                }}
              />
            </Grid>
          )}

          <TextField
            value={notesInput}
            onChange={(event) => {
              setNotesInput(event.target.value);
            }}
            multiline
            fullWidth
            rows={8}
            maxRows={8}
            placeholder="Describe the problem (optional)..."
            sx={{
              backgroundColor: theme.palette.common.white,
              '> div': {
                '> fieldset': {
                  borderColor: theme.palette.primary.light,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => goToNextPage()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
