export class Notes {
  public notes: string;
  public clientNotes: string;
  public sessionNotes: string;

  constructor(notes: string, clientNotes: string, sessionNotes: string) {
    this.notes = notes;
    this.clientNotes = clientNotes;
    this.sessionNotes = sessionNotes;
  }

  public setNotes(notes: string) {
    this.notes = notes;
  }

  public setClientNotes(clientNotes: string) {
    this.clientNotes = clientNotes;
  }

  public getNotes(transfer?: boolean | null) {
    return transfer ? this.getTransferNotes() : this.getSummaryNotes();
  }

  private getTransferNotes(): string {
    return `${this.getFormattedPreviousSessionBookingNotes()}${
      this.sessionNotes ? `\n${this.getFormattedPreviousSessionNotes()}` : ''
    }${this.clientNotes ? `\n${this.getFormattedClientNotes()}` : ''}`;
  }

  private getSummaryNotes(): string {
    return this.clientNotes
      ? `${this.getFormattedClientNotes()}\n\n${this.getFormattedCustomerNotes()}`
      : this.notes;
  }

  private getFormattedPreviousSessionBookingNotes(): string {
    return this.formatNotes('PREVIOUS SESSION BOOKING NOTES', this.notes);
  }

  private getFormattedCustomerNotes(): string {
    return this.formatNotes('CUSTOMER NOTES', this.notes);
  }

  private getFormattedPreviousSessionNotes(): string {
    return this.formatNotes('PREVIOUS SESSION NOTES', this.sessionNotes);
  }

  private getFormattedClientNotes(): string {
    return this.formatNotes('CLIENT NOTES', this.clientNotes);
  }

  private formatNotes(title: string, notes: string) {
    return `${title}\n-----\n${notes}`;
  }
}
