import {
  AuthContext,
  ClientContext,
  CustomerContext,
  RemoteAssistContext,
  TechnicianContext,
} from 'contexts';

import { sendRemoteAssistBookedMessage } from 'utils';
import { useContext } from 'react';

export const useCreateRemoteAssist = () => {
  const { makeRequest } = useContext(AuthContext);
  const { clientId, supplyClientId } = useContext(ClientContext);
  const { reference, productType, serviceCodeId, notes, parentRemoteAssist } =
    useContext(RemoteAssistContext);
  const {
    customerReference,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    zipCode,
    isMember,
  } = useContext(CustomerContext);
  const {
    firstName: technicianFirstName,
    lastName: technicianLastName,
    emailAddress: technicianEmailAddress,
    phoneNumber: technicianPhoneNumber,
  } = useContext(TechnicianContext);

  return async (overrideRa: object): Promise<any> => {
    const { data: ra } = await makeRequest(`/remote-assists`, {
      method: 'POST',
      body: JSON.stringify({
        ...(reference && { reference }),
        client_id: clientId,
        supply_client_id: supplyClientId,
        trade: serviceCodeId,
        ...(productType === 'TechToTech'
          ? {
              attendees: [
                {
                  contact: {
                    ...(technicianFirstName && {
                      first_name: technicianFirstName,
                    }),
                    ...(technicianLastName && {
                      last_name: technicianLastName,
                    }),
                    ...(technicianEmailAddress && {
                      email: technicianEmailAddress,
                    }),
                    ...(technicianPhoneNumber && {
                      phone: technicianPhoneNumber,
                    }),
                  },
                },
              ],
            }
          : {
              ...(zipCode && { service_location: zipCode }),
              attendees: [
                {
                  ...(customerReference && { reference: customerReference }),
                  is_member: isMember,
                  contact: {
                    ...(firstName && { first_name: firstName }),
                    ...(lastName && { last_name: lastName }),
                    ...(emailAddress && { email: emailAddress }),
                    ...(phoneNumber && { phone: phoneNumber }),
                  },
                },
              ],
            }),
        notes: notes.getNotes(
          parentRemoteAssist && parentRemoteAssist.relation === 'transfer',
        ),
        ...(parentRemoteAssist && { parent: parentRemoteAssist }),
        ...(productType && {
          product_type: productType,
        }),
        ...overrideRa,
      }),
    });
    if (!ra) {
      throw new Error('Error during remote assist creation.');
    }
    return ra;
  };
};

export const useCreateOnDemandRemoteAssist = () => {
  const createRemoteAssist = useCreateRemoteAssist();
  const { assignTo, setOnDemandRemoteAssist } = useContext(RemoteAssistContext);
  const { zipCode } = useContext(CustomerContext);

  return async (): Promise<any> => {
    const ra = await createRemoteAssist({
      schedule: {
        type: 'immediate',
        ...(assignTo && {
          assign_to: assignTo,
        }),
      },
    });
    setOnDemandRemoteAssist(ra);
    sendRemoteAssistBookedMessage({
      ...ra,
      type: 'ON_DEMAND',
      customer: { zipCode },
      assignTo,
    });
    return ra;
  };
};
